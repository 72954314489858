html, body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* You can add global styles to this file, and also import other style files */
body {
  //Default colors
  --g-primary:  #FF5756;
  --g-primary-contrast: #FFFFFF;
  --g-warning: #EF426F;
  --nav-web-primary: black;
  --nav-web-secondary: white;

  --g-font-headline: 'lato', sans-serif;
  --g-font-body: 'lato', medium-content-serif-font, Georgia, Cambria, "Times New Roman", Times, serif;
  --g-font-accent: "Caveat";

  --g-global-corner-radius: 8px;
    
  --local-divider: #FAFBFC;
  --local-text-color: #222222;


  h1, h2, h3, h4, h5, h6 {
    font-family: var(--g-font-headline);
    font-weight: 400;
    color: var(--local-text-color);
  }
  p, div, span, li {
    font-family: var(--g-font-body);
    color: var(--local-text-color);
  }

  a {
    color: var(--local-text-color);
    text-decoration: none;
  }
}